<template>
  <div class="nav-top">
    <div class="item" v-for="(item,index) in nav" :key="index">
      <div class="item-img">
        <img :src="item.img" alt>
      </div>
      <div class="item-info">
        <div class="num"><i>{{item.num}}</i>{{item.status===0?'份':'位'}}</div>
        <div class="name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    nav: {
      type: Array
    }
  },
  data() {
    return {
      // nav:[
      //   {name:'共评价表',num:8,img:require("@/assets/img/base/con_pjb.png"),status:0},
      //   {name:'已完成',num:5,img:require("@/assets/img/base/con_ywc.png"),status:0},
      //   {name:'未完成',num:3,img:require("@/assets/img/base/con_wwc.png"),status:0},
      //   {name:'共评价老师',num:3,img:require("@/assets/img/base/con_gpjls.png"),status:1},
      //   {name:'待评价老师',num:3,img:require("@/assets/img/base/con_dpjls.png"),status:1},
      // ],
    };
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/css/mixin.scss";
.nav-top {
  flex: 1;
  height: 85px;
  background-color: #f9f9ff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  .item {
    @include flex();
    .item-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 12px;
      img {
        width: 100%;
      }
    }
    .item-info {
      text-align: center;
      .num {
        font-size: 16px;
        color: #1c9fff;
        margin-bottom: 4px;
        i{
          font-weight: bold;
        }
      }
      .name {
        font-size: 14px;
      }
    }
  }
}
</style>